// 主体信息
<script>
import api from '@/command/api'
import TemplateHeaderTable from '../../../components/TemplateHeaderTable'
import { getAction, postAction, putAction } from '@/command/netTool'
import apiTool from '@/command/apiTool'
import DrawerForm from '../../../components/DrawerForm'
import ScoreModal from '@/components/ScoreModal'
import RelationModal from '@/components/RelationModal'
import SetMainModal from '@/components/SetMainModal'

export default {
  name: 'entity-information',
  data() {
    return {
      ...api.command.getState(),
      labelList: [],
      // 评分设置弹窗
      showScoreModal: false,
      showRelationModal: false,
      showMainModal: false,
      currentRecord:{ }
    }
  },
  mounted() {
    api.command.search
      .call(this, {
        url: '/commodity/farmMainBody/getLabelQueryConditionList',
      })
      .then((res) => {
        this.labelList = res.map((e) => ({
          name: e.labelName,
          value: e.id,
        }))
        this.$forceUpdate()
      })
    let pageCurrent = localStorage.getItem('pageCurrent') ? localStorage.getItem('pageCurrent') : 1

    localStorage.removeItem('pageCurrent')
    api.command.getList.call(this, {
      url: '/commodity/farmMainBody/page',
      current: pageCurrent,
    })
  },
  methods: {
    getHeader() {
      return [
        {
          name: '名称',
          type: 'input',
          key: 'mainBodyName',
        },
        {
          name: '电话',
          type: 'input',
          key: 'linkPhone',
        },
        {
          name: 'ID',
          type: 'input',
          key: 'id',
        },
        {
          name: '地址',
          type: 'input',
          key: 'address',
        },
        {
          name: '关联主体',
          type: 'input',
          key: 'firmName',
        },
        {
          name: '标签',
          type: 'select',
          key: 'plantOtherLabel',
          typeData: this.labelList,
          showSearch: true,
          props: {
            showSearch: true,
            mode: 'multiple',
            // filterOption: (input, option) =>
            //   option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0,
          },
        },
      ]
    },
    getColumns() {
      return [
        {
          dataIndex: 'id',
          title: 'ID',
          align: 'left',
          sorter: (a, b) => a.id - b.id,
        },
        {
          dataIndex: 'mainBodyName',
          title: '名称',
          filter: true,
          align: 'left',
          sorter: (a, b) => a.mainBodyName.length - b.mainBodyName.length,
        },
        {
          dataIndex: 'address',
          title: '地址',
          align: 'left',
          sorter: (a, b) => a.address.length - b.address.length,
          customRender: (text, record) => {
            return <div>{text && text.length > 20 ? text.substring(0, 20) + '...' : text}</div>
          },
        },
        {
          dataIndex: 'firmName',
          title: '关联主体',
          align: 'left',
          sorter: (a, b) => a.firmName - b.firmName,
        },
        {
          dataIndex: 'linkPhone',
          title: '电话',
          align: 'left',
          sorter: (a, b) => a.linkPhone - b.linkPhone,
          customRender: (text, record) => {
            return <div>{text && text.length > 15 ? text.substring(0, 15) + '...' : text}</div>
          },
        },
        {
          dataIndex: 'farmLabelDataVOS',
          title: '标签',
          align: 'left',
          getData: (records) =>
            records.farmLabelDataVOS
              .filter((e) => e)
              .slice(0, 2)
              .map((e) => e.labelName),
          type: 'tagGroup',
          onExport: (text, records) => {
            if (!text.includes(null)) {
              let arr = text.map((e) => {
                return e.labelName
              })
              return arr.toString()
            }
          },
        },
        {
          dataIndex: 'festivalNum',
          title: '农事节庆',
          align: 'center',
          width: 60,
          customRender: function (text, records) {
            if (text == 0) {
              return (
                <div class="nsjq">
                  <span class="wu">无</span>
                </div>
              )
            } else {
              return (
                <div class="nsjq">
                  <span class="you">{text}</span>
                </div>
              )
            }
          },
        },
        {
          dataIndex: 'shopType',
          title: '店铺类型',
          align: 'center',
          width: 60,
          customRender: function (text, records) {
            if (text == 1) {
              return '总店'
            } else {
              return '普通店铺'
            }
          },
        },
        {
          dataIndex: 'state',
          title: '状态',
          type: 'badge',
          width: 80,
          onExport: (records) => {
            return ['上架', '下架'][records]
          },
          filters: [
            {
              text: '上架',
              value: '0',
            },
            {
              text: '下架',
              value: '1',
            },
          ],
          filterMultiple: false,
          render(data) {
            return {
              showDot: true,
              name: data == 0 ? '上架' : '下架',
              color: data == 0 ? 'green' : 'red',
            }
          },
        },
        {
          dataIndex: 'a7',
          title: '操作',
          type: 'buttonGroup',
          fixed: 'right',

          typeData: ({ records }) => {
            return [
              {
                name: records.state == 0 ? '下架' : '上架',
                onClick: () => {
                  api.command.edit.call(this, {
                    url: `/commodity/farmMainBody/${records.state == 0 ? 'down' : 'up'}/${records.id}`,
                  })
                },
              },
              {
                name: '手续费',
                onClick: () => {
                  getAction(`/api/commodity/farmMainBody/findRate/${records.id}`).then((res) => {
                    this.poundage(res.data)
                  })
                },
              },
              {
                name: '收款二维码',
                onClick: () => {
                  this.getUrlData(records.id, 1)
                },
              },
              {
                name: '私有店铺',
                onClick: () => {
                  this.getUrlData(records.id, 2)
                },
              },
              {
                name: '设置店铺',
                onClick: () => {
                  this.currentRecord = records
                  this.showMainModal = true
                },
              },
              {
                name: '集团店铺',
                isHidden: records.shopType == 0,
                onClick: () => {
                  this.currentRecord = records
                  this.showRelationModal = true
                },
              },
              {
                name: '编辑',
                onClick: () => {
                  localStorage.setItem('pageCurrent', this.current)
                  this.$router.push(`/commodityManagement/entityInformationDetail?id=${records.id}`)
                },
              },
              {
                name: '删除',
                type: 'pop',
                popTitle: '确认是否删除吗?',
                onClick: () =>
                  api.command.del.call(this, {
                    url: `/commodity/farmMainBody/${records.id}`,
                  }),
              },
            ].filter((d) => !d.isHidden)
          },
        },
      ]
    },
    getUrlData(id, index) {
      getAction('/commodity/farmMainBody/getQrCode/' + id, {}, '/api').then((res) => {
        if (res.code == 200) {
          let setData = index == 1 ? res.data.qrCode : res.data.privateIndexUrl
          this.setUrlData(setData)
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    setUrlData(dataUrl) {
      const a = document.createElement('a')
      a.href = dataUrl
      a.style.display = 'none'
      a.target = '_blank'
      document.body.appendChild(a)
      a.click()
      document.body.removeChild(a)
    },
    poundageForm(dataSourse) {
      return [
        {
          form: [
            {
              name: '店铺提现手续费（%）',
              type: 'inputNumber',
              key: 'rate',
            },
            {
              name: '租户手续费',
              type: 'text',
              value: (dataSourse.tenantRate ?? 0) + '%',
              labelCol: { span: 3 },
              wrapperCol: { span: 20 },
              align: 'right',
              styles: {
                borderBottom: '1px solid #bfbfbf',
                marginLeft: '-70px',
                color: '#bfbfbf',
              },
              styleName: {
                fontWeight: 400,
                color: '#bfbfbf',
              },
            },
            {
              name: '店铺手续费',
              type: 'text',
              value: (dataSourse.rate ?? 0) + '%',
              labelCol: { span: 3 },
              wrapperCol: { span: 20 },
              align: 'right',
              styles: {
                borderBottom: '1px solid #bfbfbf',
                marginLeft: '-70px',
                color: '#bfbfbf',
              },
              styleName: {
                fontWeight: 400,
                color: '#bfbfbf',
              },
            },
            {
              name: '结算手续费',
              type: 'text',
              value: (dataSourse.rate ? dataSourse.rate + dataSourse.tenantRate : 0) + '%',
              labelCol: { span: 3 },
              wrapperCol: { span: 20 },
              align: 'right',
              styles: {
                borderBottom: '1px solid #bfbfbf',
                marginLeft: '-70px',
                color: '#000',
              },
            },
          ],
        },
      ]
    },
    poundage(dataSourse = {}) {
      let that = this
      apiTool.showDrawer({
        title: '手续费',
        width: '600px',
        view: DrawerForm,
        viewProps: {
          data: () => that.poundageForm(dataSourse),
          form: dataSourse,
        },
        success: ({ data, setHidden }) => {
          if (data.rate + data.tenantRate < 0) {
            this.$message.warning('结算手续费必须大于或等于零')
            return false
          }
          api.command[data.id ? 'editPost' : 'create']
            .call(this, {
              url: '/commodity/farmMainBody/saveRate',
              params: {
                ...data,
              },
            })
            .then(setHidden)
        },
        foot: ({ close, submit }) => {
          return [
            {
              name: '取消',
              onClick: close,
            },
            {
              name: '保存',
              type: 'primary',
              loading: false,
              onClick: submit,
            },
          ]
        },
      })
    },
    getButton() {
      return [
        {
          name: '添加',
          type: 'primary',
          icon: 'plus',
          onClick: () => this.$router.push('/commodityManagement/entityInformationDetail'),
        },
        {
          viewType: 'menu',
          name: '更多操作',
          isBatch: true,
          children: [
            {
              name: '批量删除',
              onClick: (data) => {
                let idList = data.map((e) => e.id)
                return api.command.delPut.call(this, {
                  url: '/commodity/farmMainBody/batchDel',
                  params: { idList },
                })
              },
            },
          ],
        },
        {
          name: '店铺设置',
          type: 'primary',
          ghost: true,
          onClick: () => this.$router.push('/commodityManagement/entityInformationStoreSettings'),
        },
        {
          name: '评分设置',
          type: 'primary',
          ghost: true,
          onClick: () => {
            this.showScoreModal = true
          },
        },
      ]
    },
    renderModal() {
      return (
        <div>
          <ScoreModal
            getDataAPI={() => {
              return getAction('/config/farmConfigHome/detail', {}, '/operation').then((res) => {
                const data = res.data

                return {
                  ...data,
                  openScore: data.shopScoreState == '1',
                  productLikeState: data.productLikeState == '1',
                  evaluateState: data.evaluateState == '1',
                }
              })
            }}
            updateDataAPI={(params) => {
              const { openScore, evaluateState } = params

              return postAction(
                '/config/farmConfigHome/update',
                { ...params, shopScoreState: openScore ? '1' : '0' },
                '/operation'
              )
            }}
            v-model={this.showScoreModal}
          />
        </div>
      )
    },
  },
  render() {
    return (
      <div>
        <TemplateHeaderTable
          headerData={this.getHeader()}
          tableColumns={this.getColumns()}
          button={this.getButton()}
          records={this.records}
          ref="table"
        />
        {this.renderModal()}
        <RelationModal
          v-model={this.showRelationModal}
          shopId={this.currentRecord.id}
          updateDataAPI={(params) => {
            return putAction('/commodity/farmMainBody/saveGroup', { parentId: this.currentRecord.id, ...params })
          }}
          loadPage={()=> this.$refs.table.reloadPage() }
        />
        <SetMainModal
          v-model={this.showMainModal}
          shopType={this.currentRecord && this.currentRecord.shopType == 1 ? true : false}
          parentName={this.currentRecord.parentName}
          updateDataAPI={(params) => {
            return putAction('/commodity/farmMainBody/updateShopType', { id: this.currentRecord.id, ...params })
          }}
          loadPage={()=> this.$refs.table.reloadPage() }
        />
      </div>
    )
  },
}
</script>
<style lang="less" scoped>
.nsjq {
  display: flex;
  align-items: center;
  justify-content: center;

  span {
    display: inline-block;
    width: 28px;
    height: 20px;
    line-height: 18px;
    border-radius: 3px;
    font-size: 12px;
  }

  .wu {
    color: #808695;
    background-color: rgba(247, 247, 247, 1);
    border: 1px solid rgba(220; 222; 226; 1);
  }

  .you {
    color: #fff;
    background-color: rgba(44, 140, 240, 1);
  }
}
</style>
