<script>
import { Modal, Select, Table, Button } from 'ant-design-vue'
import { getAction } from '@/command/netTool'

export default {
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    getDataAPI: {
      type: Function,
    },
    updateDataAPI: {
      type: Function,
    },
    shopId: {
      type: String,
      default: '',
    },
    loadPage: {
      type: Function,
    },
  },
  data() {
    return {
      show: this.value,
      shopList: [],
      dataSource: [],
    }
  },
  watch: {
    value(val) {
      if (val) {
        setTimeout(() => {
          getAction('/commodity/farmMainBody/queryNormalList', { shopId: this.shopId }).then((res) => {
            this.shopList = res.data
          })
          getAction('/commodity/farmMainBody/queryShopGroupList', { shopId: this.shopId }).then((res) => {
            if (res.data.length == 0) {
              this.dataSource = []
            } else {
              this.dataSource = res.data.map((d) => d.shopId)
            }
          })
        })
      }
      this.show = val
    },
    show(val) {
      this.$emit('input', val)
    },
  },
  mounted() {},
  methods: {
    onConfirm() {
      if (this.updateDataAPI) {
        this.updateDataAPI({
          childDTOList: this.dataSource.map((d) => ({ shopId: d })),
        }).then((res) => {
          this.$message.success('关联成功')
          this.loadPage()
          this.show = false
          //   this.dataSource = [{ shopId: '' }]
        })
      }
    },
    onSelect(value, index) {
      this.dataSource = value
    },
  },
  render() {
    return (
      <Modal
        title="关联店铺"
        v-model={this.show}
        onOk={this.onConfirm}
        width={600}
        onCancel={() => {
          this.show = false
        }}
      >
        <Select
          style={{ width: '100%' }}
          mode="multiple"
          onChange={this.onSelect}
          placeholder="请选择店铺"
          value={this.dataSource}
          filterOption={(inputValue, option) => {
            const name = option.data.attrs.name
            return name.indexOf(inputValue) > -1
          }}
        >
          {this.shopList.map((item) => {
            return (
              <Select.Option value={item.id} name={item.mainBodyName}>
                {item.mainBodyName}
              </Select.Option>
            )
          })}
        </Select>
      </Modal>
    )
  },
}
</script>
<style lang="less" scoped></style>
