<script>
import { Modal, Form, Switch } from 'ant-design-vue'

export default {
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    updateDataAPI: {
      type: Function,
    },
    shopType: {
      type: Boolean,
      default: false,
    },
    loadPage: {
      type: Function,
    },
    parentName: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      show: this.value,
      shopType: false,
      params: {},
    }
  },
  watch: {
    value(val) {
      this.show = val
    },
    show(val) {
      this.$emit('input', val)
    },
  },
  mounted() {
    // if (this.getDataAPI) {
    //   this.getDataAPI().then(res => {
    //     this.shopType = res.shopType
    //     this.params = res
    //   })
    // }
  },
  methods: {
    onConfirm() {
      if (this.parentName) {
        const that = this
        Modal.confirm({
          title: '设置店铺',
          content: `目前关联【${this.parentName}】总店，开启后将解除关联`,
          onOk() {
            if (that.updateDataAPI) {
              that
                .updateDataAPI({
                  ...that.params,
                  shopType: that.shopType ? '1' : '0',
                })
                .then((res) => {
                  that.$message.success('设置成功')
                  that.loadPage()
                  that.show = false
                })
            }
          },
          onCancel() {},
        })
      } else {
        if (this.updateDataAPI) {
          this.updateDataAPI({
            ...this.params,
            shopType: this.shopType ? '1' : '0',
          }).then((res) => {
            this.$message.success('设置成功')
            this.loadPage()
            this.show = false
          })
        }
      }
    },
  },
  render() {
    return (
      <Modal
        title="设置店铺"
        v-model={this.show}
        onOk={this.onConfirm}
        onCancel={() => {
          this.show = false
        }}
      >
        <Form>
          <Form.Item label="设为主店铺" labelCol={{ span: 4 }} wrapperCol={{ span: 14 }}>
            <Switch v-model={this.shopType} />
          </Form.Item>
        </Form>
      </Modal>
    )
  },
}
</script>
<style lang="less" scoped></style>
